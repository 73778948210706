<template>
  <div>
    <CrudCreate
      emitInput
      v-bind="this.$store.state.stone_model.service_plan"
      v-model="state"
      @submit="$_onSubmit($event)"
    >
    </CrudCreate>
  </div>
</template>

<script>
import S_Api_ServicePlanItem from "@/__vue2stone_cms/service/api/v1/service_plan_item";
import S_Api_ServicePlan from "@/__vue2stone_cms/service/api/v1/service_plan";
import servicePlanItemModel from "@/__vue2stone_cms/models/service_plan_item";
export default {
  metaInfo() {
    return {
      title: `${this.$t("新增")} ${this.$t(this.label)}`,
    };
  },
  methods: {
    async $_onSubmit() {
      const postData = JSON.parse(JSON.stringify(this.state));
      try {
        await S_Api_ServicePlan.create(postData);
        this.$router.push("/service_plan");
      } catch (error) {
        console.error(error);
      } finally {
        this.$refs.WsCreate.loadingStop();
      }
    },
    $_getStateLabel(item) {
      const _type = servicePlanItemModel.fields.type.items.find((e) => {
        return e.value == item.type;
      });
      return `${item.name} (${_type.text})`;
    },
    $_onStateInput($event, item) {
      let _state = {};
      if (this.state) {
        _state = {
          ...this.state,
        };
      }
      if (!_state.payload) {
        _state.payload = {};
      }
      _state.payload = {
        ..._state.payload,
        [item.uuid]: $event,
      };
      this.state = _state;
    },
    $_getStateValue(item) {
      if (this.state && this.state.payload) {
        return this.state.payload[item.uuid];
      } else {
        return null;
      }
    },
    $_getStateTypeFromItemType(itemType) {
      let _type = "text";
      if (itemType == "custom-items") {
        _type = "select";
      } else if (itemType == "discount") {
        _type = "number";
      } else if (itemType == "annual-times") {
        _type = "number";
      } else if (itemType == "count") {
        _type = "number";
      } else if (itemType == "boolean") {
        _type = "switch";
      }
      return _type;
    },
    async $_fetchServicePlanItems() {
      const res = await S_Api_ServicePlanItem.index();
      this.servicePlanItems = res;
    },
  },
  mounted() {
    this.$_fetchServicePlanItems();
  },
  data() {
    return {
      servicePlanItems: [],
      state: null,
    };
  },
};
</script>

<style>
</style>